// Here you can add other styles
.overly-loader{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 999999999999;
  cursor: pointer;
}
.hidder{
    display:none;
}
.show{
    display:block;
}

.overly-loader .loader-img{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
.overly-loader .loader-img p{
  font-size: 12px;
}
.overly-loader .loader-img img{
  width: 30px;
}
